<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Login v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <vuexy-logo />

                    <h2 class="brand-text text-primary ml-1">Utilis</h2>
                </b-link>

                <b-card-title class="mb-1">
                    {{ $t("modules.auth.welcome") }} Utilis! 👋
                </b-card-title>
                <b-card-text class="mb-2">
                    {{ $t("modules.auth.credentialMessage") }}
                </b-card-text>

                <formulateForm
                    v-slot="{ isLoading, hasErrors }"
                    v-model="formData"
                    name="loginForm"
                    @submit="handleSubmit"
                >
                    <b-card>
                        <b-row class="mb-2">
                            <b-col cols="12">
                                <formulateInput
                                    autofocus
                                    :label="$tc('common.email.description', 1)"
                                    name="email"
                                    placeholder="email@provider.com"
                                    type="text"
                                    validation="required|email"
                                />

                                <formulateInput
                                    :elementClass="[
                                        'input-group',
                                        'input-group-merge',
                                    ]"
                                    :inputClass="['form-control-merge']"
                                    :label="$t('modules.auth.password')"
                                    name="password"
                                    :type="passwordFieldType"
                                    validation="required"
                                >
                                    <template #suffix>
                                        <b-input-group-append isText>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="
                                                    togglePasswordVisibility
                                                "
                                            />
                                        </b-input-group-append>
                                    </template>
                                </formulateInput>
                                <b-link :to="{ name: 'auth-forgot-password' }">
                                    <small>{{
                                        $t("modules.auth.forgotPassword")
                                    }}</small>
                                </b-link>
                            </b-col>
                        </b-row>

                        <formulateErrors />

                        <formulateInput
                            :disabled="hasErrors || isLoading"
                            :label="
                                isLoading
                                    ? $tc('modules.auth.loggingIn', 1)
                                    : $tc('modules.auth.loggingIn', 2)
                            "
                            inputClass="btn btn-primary"
                            type="submit"
                        />
                    </b-card>
                </formulateForm>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardText,
    BCardTitle,
    BCol,
    BInputGroupAppend,
    BLink,
    BRow,
} from "bootstrap-vue"
import VuexyLogo from "@core/layouts/components/Logo.vue"
import { email, required } from "@validations"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
    components: {
        // BSV
        BCard,
        BCardTitle,
        BCol,
        BInputGroupAppend,
        BLink,
        BRow,
        VuexyLogo,
        BCardText,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            formData: {
                email: "",
                password: "",
            },
            // validation rules
            required,
            email,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon"
        },
    },
    methods: {
        async handleSubmit() {
            try {
                await this.$http.get("/sanctum/csrf-cookie")
                await this.$http.post("/login", { ...this.formData })
                const userInfoResponse = await this.$http.get("/api/user")
                const userData = userInfoResponse.data.data

                localStorage.setItem("userData", JSON.stringify(userData))

                this.$ability.update(userData.permissions)

                this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: this.$t("modules.auth.welcome"),
                        icon: "CoffeeIcon",
                        variant: "success",
                        text: this.$t("modules.auth.loginSuccess"),
                    },
                })

                this.$router.replace("/")
            } catch (e) {
                this.$formulate.handle(e, "loginForm")
            }
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
