var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v("Utilis")])],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("modules.auth.welcome"))+" Utilis! 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("modules.auth.credentialMessage"))+" ")]),_c('formulateForm',{attrs:{"name":"loginForm"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var hasErrors = ref.hasErrors;
return [_c('b-card',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('formulateInput',{attrs:{"autofocus":"","label":_vm.$tc('common.email.description', 1),"name":"email","placeholder":"email@provider.com","type":"text","validation":"required|email"}}),_c('formulateInput',{attrs:{"elementClass":[
                                    'input-group',
                                    'input-group-merge' ],"inputClass":['form-control-merge'],"label":_vm.$t('modules.auth.password'),"name":"password","type":_vm.passwordFieldType,"validation":"required"},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('b-input-group-append',{attrs:{"isText":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)]},proxy:true}],null,true)}),_c('b-link',{attrs:{"to":{ name: 'auth-forgot-password' }}},[_c('small',[_vm._v(_vm._s(_vm.$t("modules.auth.forgotPassword")))])])],1)],1),_c('formulateErrors'),_c('formulateInput',{attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                                ? _vm.$tc('modules.auth.loggingIn', 1)
                                : _vm.$tc('modules.auth.loggingIn', 2),"inputClass":"btn btn-primary","type":"submit"}})],1)]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }